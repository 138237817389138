import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


// Загальні дані замовлення для "Самовивіз"
const ViewOrdersPickupDelivery = (props) => {
    console.log('ViewOrdersPickupDelivery props.data', props.data);
    const data = props.data;
    const date = new Date(data.dateEntered);
    const dateEntered = date.toISOString().slice(0, 10);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                <p className='m-0'>Контрагент: <strong>{data.account.name}</strong> | {data.account.phone}</p>
                                <p className='m-0'>менеджер: <strong>{(data.manager != undefined) ? `${data.manager.firstName} ${data.manager.lastName}` : 'Ще не призначениий'}</strong></p>
                            </TableCell>
                            <TableCell align="right">
                                {/* <p className='m-0'>дата створення: <strong>{dateEntered}</strong></p> */}
                                <p className='m-0'>дата створення: <strong>{data.dateEntered}</strong></p>
                                <p className='m-0'>синхронізовано в 1с: <strong>{(data.synced) ? 'Так' : 'Ні'}</strong></p>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Статус:</TableCell>
                            <TableCell align="left"><strong>{
                                props.status.map((v, k) => {
                                    if (data.status == v.value)
                                        return v.label;
                                })
                            }</strong>
                            </TableCell>
                            <TableCell align="right">дата виконання:</TableCell>
                            <TableCell align="left"><strong>{data.dateDelivery}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Тип доставки:</TableCell>
                            <TableCell align="left">{data.deliveryMethod}</TableCell>
                            <TableCell align="right">Тип оплати:</TableCell>
                            <TableCell align="left">{data.paymentMethod}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Адреса:</TableCell>
                            {/* <TableCell align="left" colSpan={3}>{`${data.shippingAddressCountry} ${data.shippingAddressCity} ${data.shippingAddressStreet} ${data.shippingAddressBuilding}`}</TableCell> */}
                            <TableCell align="left" colSpan={3}>{`${data.shippingAddressCity}, ${data.storeAddress}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Опис:</TableCell>
                            <TableCell align="left" colSpan={3}><div dangerouslySetInnerHTML={{ __html: data.description }}></div></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Можна не телефонувати для <br />підтвердження замовлення:</TableCell>
                            <TableCell>{(data.notCall) ? "Так" : "Ні"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {rows.map((row) => (
                                <TableRow key={row.desc}>
                                    <TableCell>{row.desc}</TableCell>
                                    <TableCell align="right">11111</TableCell>
                                    <TableCell align="right">222222</TableCell>
                                    <TableCell align="right">333333333</TableCell>
                                </TableRow>
                            ))} */}
                        {/* <TableRow>
                                <TableCell rowSpan={4} colSpan={2} />
                                <TableCell >Валюта</TableCell>
                                <TableCell><strong>{data.curencyRate}</strong></TableCell>
                            </TableRow> */}
                        {/* <TableRow>
                                <TableCell>Курс</TableCell>
                                <TableCell><strong>{data.curency}</strong></TableCell>
                            </TableRow> */}
                        <TableRow>
                            <TableCell>Вартість товару:</TableCell>
                            <TableCell>{data.sumOrdered} грн.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Знижка:</TableCell>
                            <TableCell>-{data.discountTotal} грн.  {(data.discountPercent != 0) ? `(-${data.discountPercent}%)` : ""}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Сума:</TableCell>
                            {/* <TableCell><strong>{`${data.sumOrdered}-${data.discountTotal}`} грн.</strong></TableCell> */}
                            <TableCell><strong>{(data.sumOrdered - data.discountTotal).toFixed(2)} грн.</strong></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ViewOrdersPickupDelivery;