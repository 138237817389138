import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton
} from '@coreui/react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import AddItemGallery from 'src/components/gallery/AddItemGallery';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import GalleryList from 'src/components/gallery/GalleryList';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";


export async function LoaderGalleryUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await AxiosInstance.get('/galleries/' + params.id).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const GalleryForm = ({user_data}) => {
  if(!Permissions(user_data, 'Gallery', 'EDIT_GALLERY')){
      return <PermissionsNoPage />;
  }
  const {data}  = useLoaderData();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined)? data.newsShot : '');
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const popup = MyToastify();

  const handleSubmit = event => {
    event.preventDefault();

   
    
    const article = {
        name: name,
        // newsShot: newsShot,
        // newsFull: editorRef.current.getContent(),
      };
      if (data != undefined){
        const response = AxiosInstance.put('/galleries/' + data.id , article).then((response) => {
          if(response.status == 200){
            popup.toastifySuccess('Галерею оновлено');
          }
        });
      }else {
        const response = AxiosInstance.post('/galleries', article).then((response) => {
          console.log(response);
          if (response.status == 201) {
            popup.toastifySuccess("Галерею створено")
            navigate(`/gallery/update/${response.data.id}`);
            
          }
        });
      }
    
  }

  const [showModal, setShowModal] = useState(true);

  const actionShowModal = (rez) => {
    setShowModal(rez);
    setUpdateLists(!updateLists);
  }

  const [updateLists, setUpdateLists] = useState(false);

    return (
      <>
        <ToastContainer />
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва галереї</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <CButton type="submit" color="success">Зберегти</CButton>
          
        </CForm>
        <hr/>
        {(data != undefined) &&
          <div className='row'>
            <div className='col-12'>
                <BasicModal
                  show={showModal}
                  actionShowModal={actionShowModal}
                  title={`Додати  - ${data.name}`}
                  btn_name={<>Додати об'єкт</>}
                  content={
                    <AddItemGallery toastifySuccess={popup.toastifySuccess} parent_id={data.id}  actionShowModal={actionShowModal}/>
                  }
                /> 
            </div>
            <div className='col-12'>
              <GalleryList gallery_id={data.id} update={updateLists}/>
            </div>
          </div> 
        }
      </>
    );
};

export default GalleryForm;
