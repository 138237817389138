// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.materials-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #d4d4d4;
    overflow: hidden;
  }
  
  .materials-table th,
  .materials-table td {
    border: 1px solid #d4d4d4;
    padding: 10px;
    text-align: center;
  }
  
  .materials-table th {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .materials-table td {
    background-color: rgba(0, 0, 0, 0.01);
  }
  
  .materials-table tr:nth-child(even) td {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .materials-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/materials.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;;IAEE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,qCAAqC;EACvC;;EAEA;IACE,qCAAqC;EACvC;;EAEA;IACE,qCAAqC;EACvC;;EAEA;IACE,qCAAqC;EACvC","sourcesContent":[".materials-table {\n    width: 100%;\n    border-collapse: separate;\n    border-spacing: 0;\n    margin-top: 20px;\n    border-radius: 10px;\n    border: 1px solid #d4d4d4;\n    overflow: hidden;\n  }\n  \n  .materials-table th,\n  .materials-table td {\n    border: 1px solid #d4d4d4;\n    padding: 10px;\n    text-align: center;\n  }\n  \n  .materials-table th {\n    background-color: rgba(0, 0, 0, 0.04);\n  }\n  \n  .materials-table td {\n    background-color: rgba(0, 0, 0, 0.01);\n  }\n  \n  .materials-table tr:nth-child(even) td {\n    background-color: rgba(0, 0, 0, 0.03);\n  }\n\n  .materials-table tbody tr:hover {\n    background-color: rgba(0, 0, 0, 0.04);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
