import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import Select from 'react-select'
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import Card from 'react-bootstrap/Card';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';


const urlApi = '/users';
const urlRouter = '/users';
const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));


export async function LoaderUsersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}


const UsersForm = ({ user_data }) => {

  if (!Permissions(user_data, 'User', 'EDIT_USER')) {
    return <PermissionsNoPage />;
  }
  const popup = MyToastify();
  // const [roleList, setRoleList] = useState([
  //   { value: 'ROLE_ADMIN', label: 'Адміністратор' },
  //   { value: 'ROLE_MANAGER', label: 'Менеджер' },
  //   { value: 'ROLE_CLIENT', label: 'Клієнт' },
  //   { value: 'ROLE_USER', label: 'Без ролі' },
  // ]);

  const [roleList, setRoleList] = useState([]); // Список ролей, які існують в адмінці
  const [userRolesList, setUserRolesList] = useState([]); // Список ролей, які додані до користувача
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [userSpecialtiesList, setUserSpecialtiesList] = useState([]);
  const [userSpecialtiesListToApi, setUserSpecialtiesListToApi] = useState([]); // Список спеціальностей в api
  const navigate = useNavigate();
  const { data } = useLoaderData();

  useEffect(() => {
    getLocations();
    getRoleList();
    if(data != undefined){
      let r = data.userRoles.map((result) => ({
        label: `${result.role.label} - ${result.role.name}`,
        value: result.role.id,
      }));
      setUserRoleList(r)
      // Видалити ролі
      setUserRoleRemoved(data.userRoles.map((result) => ({
        // label: `${result.role.label} - ${result.role.name}`,
        value: result.id,
      })))
    }

    if (role == 'ROLE_MANAGER')
      setRoleList([{ value: 'ROLE_CLIENT', label: 'Клієнт' }])
  }, []);


  const [myData, setMyData] = useState(data ? data : false);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [username, setUsername] = useState(myData?.username ? myData.username : '');
  const [plainPassword, setPlainPassword] = useState(myData?.plainPassword ? myData.plainPassword : '');
  const [firstName, setFirstName] = useState(myData?.firstName ? myData.firstName : '');
  const [lastName, setLastName] = useState(myData?.lastName ? myData.lastName : '');
  const [phone, setPhone] = useState(myData?.phone ? myData.phone : '');
  const [email, setEmail] = useState(myData?.email ? myData.email : '');
  const [telegram, setTelegram] = useState(myData?.telegram ? myData.telegram : '');
  const [viber, setViber] = useState(myData?.viber ? myData.viber : '');
  const [workSchedule, setWorkSchedule] = useState(myData?.workSchedule ? data.workSchedule : '');
  // const [roles, setRoles] = useState(() => {
  //   let rez;
  //   if (data != undefined && data.roles != undefined && data.roles.length > 0) {
  //     roleList.map((v) => {
  //       if (v.value == data.roles[0])
  //         rez = v;
  //     })
  //   }
  //   return rez;
  // });
  // console.log(roles);
  // const [address, setAddress] = useState((data != undefined) ? data.address : '');
  // const [status, setStatus] = useState((data != undefined) ? data.status : '');
  // const [codeUser, setCodeUser] = useState((data != undefined) ? data.codeUser : '');
  // const [codeManager, setCodeManager] = useState((data != undefined) ? data.codeManager : '');
  // const editorRef = useRef(null);

  // const [location, setLocation] = useState((data != undefined && data.location != undefined) ? { label: data.location.name, value: data.location.id } : {});
  const [location, setLocation] = useState(myData?.location ? { label: myData.location.name, value: myData.location.id } : {});
  const [locationList, setLocationList] = useState([]);
  // Запит за одиницями виміру

  useEffect(() => {
    if (data) {
      setMyData(data);
    }
  }, [data]);

  useEffect(() => {
    getLocations();
    getRoleList();
    getSpecialtiesList();
    if (data != undefined) {
      let role = data.userRoles.map((result) => ({
        label: `${result.role.label} - ${result.role.name}`,
        value: result.role.id,
      }));
      setUserRolesList(role);

      let specialties = data.specialty.map((result) => ({
        label: result.name,
        value: result.id,
      }));
      setUserSpecialtiesList(specialties);
    };

    if (role == 'ROLE_MANAGER')
      setRoleList([{ value: 'ROLE_CLIENT', label: 'Клієнт' }])
  }, []);

  // Функція для запиту за даними користувача
  const getUserData = async () => {
    try {
      const response = await AxiosInstance.get(`${urlApi}/${myData.id}`);
      setMyData(response.data);
    } catch (error) {
      console.error("Помилка запиту за даними користувача", error);
    };
  };

  // Функція для запиту за підрозділами
  const getLocations = async (inputValue) => {
    let rez = await AxiosInstance.get(`/locations`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
    setLocationList(rez);
    return rez;
  };

  // Функція для запит за списком ролей
  const getRoleList = async (inputValue) => {
    let rez = await AxiosInstance.get(`/roles`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: `${result.label} - ${result.name}`,
        value: result.id,
      }));
    });
    setRoleList(rez);
    return rez;
  };

  // Функція для запиту за спеціальностями
  const getSpecialtiesList = async (inputValue) => {
    let rez = await AxiosInstance.get(`/specialties`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("specialties get response", response);
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
    setSpecialtiesList(rez);
    return rez;
  };

  // Функція для видалення user_roles
  const deleteUserRole = async (id) => {
    try {
      const response = await AxiosInstance.delete(`/user_roles/${id}`);
    } catch (error) {
      console.error("Помилка видалення user_roles", error);
    };
  };

  // Функція для створення user_roles
  const createUserRole = async (id, userData) => {
    try {
      const response = await AxiosInstance.post(`/user_roles`, {
        users: `/api/users/${userData.id}`,
        role: `/api/roles/${id}`,
      });
    } catch (error) {
      console.error("Помилка створення user_roles", error);
    };
  };

  // Функція для створення/видалення user_roles
  const putUserRoles = async (userData) => {
    // console.log('userRolesList', userRolesList);

  // Відслідковувати роль менеджера, щоб сам собі не зміг змінювати
  useEffect(() => {
    if (userData.id === data.id) {
      setIsDisabledRole(true);
    } else {
      setIsDisabledRole(false);
    }
  }, [role, userData, data]);

    let rolesToCreate = []; // Список ролей, які потрібно додати до користувача
    let rolesToDelete = []; // Список ролей, які потрібно видалити від користувача

    if (myData) {
      rolesToCreate = userRolesList.filter(userRole =>
        !myData.userRoles.some(role => role.role.id === userRole.value)
      );

      rolesToDelete = myData.userRoles.filter(userRole =>
        !userRolesList.some(role => role.value === userRole.role.id)
      );
    } else {
      rolesToCreate = userRolesList;
    };

    // Обробка додавання ролей
    for (const role of rolesToCreate) {
      createUserRole(role.value, userData);
    };

    // Обробка видалення ролей
    for (const role of rolesToDelete) {
      deleteUserRole(role.id);
    };
  };

  // Функція для створення user
  const createUser = async (data_response) => {
    try {
      const response = await AxiosInstance.post(urlApi, data_response);
      // console.log('createUser response', response);
      // Оновити ролі
      await putUserRoles(response.data);
      popup.toastifySuccess('Користувача створено!');
      // Поставив затримку, оскільки не встигали оновлюватися дані по user_roles на сервері і повертало старі дані
      setTimeout(() => {
        navigate(`/users/update/${response.data.id}`);
      }, 300);
    } catch (error) {
      console.error("Помилка створення користувача", error);
    };
  };

  // Функція для оновлення user
  const updateUser = async (data_response) => {
    try {
      const response = await AxiosInstance.put(`${urlApi}/${myData.id}`, data_response);
      // Оновити ролі
      await putUserRoles(myData);
      // Поставив затримку, оскільки не встигали оновлюватися дані по user_roles на сервері і повертало старі дані
      setTimeout(() => {
        getUserData();
      }, 300);
      popup.toastifySuccess('Користувача оновлено!');
    } catch (error) {
      console.error("Помилка оновлення користувача", error);
    };
  };

  // Функція для відправлення запиту
  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (editorRef.current) {
    // console.log(editorRef.current.getContent());
    const data_response = {
      username: username,
      plainPassword: plainPassword,
      firstName: firstName,
      lastName: lastName,
      // roles: [roles.value],
      // address: address,
      // status: status,
      // codeUser: codeUser,
      // codeManager: codeManager,
      phone: phone,
      email: email,
      telegram: telegram,
      viber: viber,
      workSchedule: workSchedule,
      specialty: userSpecialtiesListToApi,
      active: 'on',
    };

    if (location.value != undefined) {
      data_response['location'] = `/api/locations/${location.value}`;
    };
    if (myData) {
      updateUser(data_response);
    } else {
      createUser(data_response);
    };
  };

  // Функція для зміни масиву з доданими спеціальностями
  const handleChangeSpecialties = (value) => {
    // console.log('handleChangeSpecialties value', value);
    setUserSpecialtiesList(value);
  };

  // Функція для зміни масиву з доданими ролями
  const handleChangeRoles = (value) => {
    // console.log('handleChangeRoles value', value);
    setUserRolesList(value);
  };

  // Підготувати спеціальності для додавання в api в IRI-форматі
  useEffect(() => {
    const specialtiesArr = userSpecialtiesList.map((specialty => `/api/specialties/${specialty.value}`));
    setUserSpecialtiesListToApi(specialtiesArr);
  }, [userSpecialtiesList]);

  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    // console.log(11111);
    // if (!rez)
    // getMedia();
  };

  return (
    <>
      <ToastContainer />
      {(myData != undefined) &&
        <div className="mb-3">
          {
            (myData?.mediaObjects && data.mediaObjects.length > 0) &&
            <img src={process.env.REACT_APP_SERVER_URL + myData.mediaObjects[0].contentUrl} alt="blog-image" height={300} className='m-5' />
          }

          <BasicModal
            show={showModalMedia}
            actionShowModal={actionShowModalMedia}
            title={`Додати зображення користувача`}
            btn_name='Додати зображення'
            content={
              <MediaFrom user_id={myData.id} actionShowModal={actionShowModalMedia} />
            }
          />
        </div>
      }

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Логін</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={username} onChange={(e) => setUsername(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Пароль</CFormLabel>
          <CFormInput type="password" id="exampleFormControlInput2" value={plainPassword} onChange={(e) => setPlainPassword(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Роль</CFormLabel>
          {/* <Select
            options={roleList}
            value={roles}
            onChange={(value) => {
              handleChangeRole(value);
            }}
          /> */}
          <Select
            value={userRolesList}
            isMulti
            name="colors"
            options={roleList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              handleChangeRole(value);
            }}
            isDisabled={isDisabledRole}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Спеціальність</CFormLabel>
          <Select
            value={userSpecialtiesList}
            isMulti
            name="colors"
            options={specialtiesList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => handleChangeSpecialties(value)}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Підрозділ</CFormLabel>
          <Select
            options={locationList}
            value={location}
            onChange={(value) => setLocation(value)}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Ім'я</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={firstName} onChange={(e) => setFirstName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Прізвище</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={lastName} onChange={(e) => setLastName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">Телефон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={phone} onChange={(e) => setPhone(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Email</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput7">Telegram</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput7" value={telegram} onChange={(e) => setTelegram(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput8">Viber</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput8" value={viber} onChange={(e) => setViber(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput9">
            Графік роботи (якщо залишити поле пустим, то виведеться стандартний графік компанії, якщо заповнити, то виводитись буде Ваш графік)
          </CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput9" value={workSchedule} onChange={(e) => setWorkSchedule(e.target.value)} autoComplete="off" />
        </div>

        {/* <div className="mb-3">
          <Editor
            apiKey='dvfh34ptn34xsf8t99ap6flco4fcps87169njgk0khjz1ylp'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>

      {
        myData &&
        <Card>
          <Card.Header>Доступи</Card.Header>
          <Card.Body>
            <table className='table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Роль</th>
                  <th>Меню</th>
                </tr>
              </thead>
              <tbody>
                {myData.userRoles.map((user_role) => {
                  return (
                    <tr key={user_role.id}>
                      <td></td>
                      <td><Link to={`/roles/update/${user_role.role.id}`} target='_blank'><strong>{user_role.role.label}</strong> - {user_role.role.name}</Link></td>
                      <td>
                        {(user_role.role.menus != undefined) &&
                          user_role.role.menus.map((menu) => {
                            return (
                              <Link to={`/menu/update/${menu.id}`} target='_blank' key={menu.id}>{menu.name}</Link>
                            )
                          })
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      }
    </>
  );
};

export default UsersForm;