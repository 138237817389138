import { useState , useEffect } from "react";
import AxiosInstance from "../Axios";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import AddItemGallery from "./AddItemGallery";
import BasicModal from 'src/components/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from "../myComponents/MyToactify";

const GalleryList = ({gallery_id, update}) => {
    const [galleryList, setGalleryList] = useState([]);
    const popup = MyToastify();
    useEffect(() => {
        AxiosInstance.get('/gallery_items', {params:{gallery: gallery_id}}).then((responce) => {
            if(responce.status == 200){
                setGalleryList(responce.data['hydra:member']);
            }
        })
    }, [gallery_id, update])
    function Deleted(row) {
        // console.log('row', row);
        confirmAlert({
          title: 'Ви впевнені що хочите видалити цей пункт ?',
          // message: 'Are you sure to do this.',
          message: (
            <>
             
            </>
          ),
          buttons: [
            {
              label: 'Так',
              onClick: () => {
                const response = AxiosInstance.delete(urlApi + '/' + row.id).then((response) => {
                  getRowsTable();
                  // Вивести popup про успішне видалення замовлення
                  popup.toastifySuccess('Видалено');
                });
              }
            },
            {
              label: 'Ні',
              onClick: () => {
                return;
              }
            }
          ]
        });
    };

    const [showModal, setShowModal] = useState(true);

    const actionShowModal = (rez) => {
      setShowModal(rez);
      setUpdateLists(!updateLists);
    }

    return (
        <>
        <CardGroup>
            {galleryList.map((val) => {
                return(
                    <Card style={{maxWidth: '400px'}} key={val.id}>
                        {(val.media != undefined && val.media.contentUrl != undefined) && <Card.Img variant="top" src={process.env.REACT_APP_SERVER_URL + val.media.contentUrl} />}
                        {(val.urlPhoto != undefined && val.urlPhoto != null && val.urlPhoto != '') && <Card.Img variant="top" src={val.urlPhoto} />}
                        {(val.urlVideo != undefined && val.urlVideo != null && val.urlVideo != '') && <video src={val.urlVideo} style={{width:'100%', height: '300px'}}></video>}
                       
                        
                        <Card.Body>
                        <Card.Title>{val.name}</Card.Title>
                        <Card.Text>{val.content}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                        <BasicModal
                          show={showModal}
                          actionShowModal={actionShowModal}
                          title={`Додати  - ${val.name}`}
                          btn_name={<>🖊 редагувати</>}
                          content={
                            <AddItemGallery toastifySuccess={popup.toastifySuccess} parent_id={gallery_id}  data={val} actionShowModal={actionShowModal}/>
                          }
                        /> 
                        </Card.Footer>
                    </Card>
                )
            })}
            
        </CardGroup>
            
        </>
    );
}

export default GalleryList;